import { PageTemplate } from './PageTemplate'
import { CandidateViewMyJobRoleComponent } from '../components/Candidate/CandidateViewMyJobRoleComponent'

const ShowCandidateMyJobRolePage = () => { 
    return (
        <PageTemplate
            type="candidate" headerPage="jobpage" headerElement="jobrole"
            leftPaneComponent={null} mainComponent={CandidateViewMyJobRoleComponent()} buttonComponent={null} >
        </PageTemplate>
    );
}
export { ShowCandidateMyJobRolePage }