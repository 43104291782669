import { createResource, For, Show, Suspense, JSX, } from "solid-js";
import { useNavigate, useParams } from "@solidjs/router";

import { Avatar, Typography, Button, IconButton, Stack, Card, Alert, Grid, Divider, Box, Switch, FormControlLabel } from "@suid/material";

import { AvatarWithWfButtons, showWfCandidateJobsBestMatchItems, showWfCandidateJobsNotStaffedItems, showWfCandidateJobsStaffedItems } from '../SmallComponents/SmallAvatarWithWfButtons'
import { CardWithMinimize } from '../AppMenuBar/CardList'
import { WFStatusComponent } from '../SmallComponents/SmallWFStatusComponent';
import { RatingComment } from '../SmallComponents/SmallShowRatingComment';
import { WFStatusFooter } from '../SmallComponents/SmallShowWFStatusFooter';
import { getCountryFromCurrencyCode } from "../../components/DataLists/DataCountryList";


import { TextElement, ValueElement, AddressElement } from '../SmallComponents/SmallTextElements';
import { AskForIcon, ContractIcon, ProposalIcon } from "../icons"

import { FieldCurrencyValue } from '../SmallComponents/SmallFieldCurrencyValue'
import { FieldAddress } from "../SmallComponents/SmallFieldAddress";
import { AvatarChipWithIcon, WFChip } from '../SmallComponents/SmallChips';
import { CandidateJobMenu } from './CandidateJobMenu'

import { ShowSocialMedia } from '../SmallComponents/SmallSocialMedia';

import { CompetenceList } from '../SmallComponents/SmallChipsCompetence'
import { isSmall, isExtraSmall, isLarge, isExtraLarge } from "../../../utils/sizeUtil"

import { getText, GetTextUI } from '../SmallComponents/Translate'
import { formatDateYearMonthByCountry } from "../../../utils/datetimeflag"

import { CandidateJobRoleFetchType, CandidateJobFetchType } from "../../../types/candidateJobDataTypes"

const apiUrl = import.meta.env.VITE_APP_API_URL;

const countryCode = "DK"



const fetchJob = async (jobRoleId: string | null): Promise<CandidateJobRoleFetchType> => {
    const response = await fetch(`${apiUrl}/candidate/candidatejobrole/${jobRoleId}`, {
        method: "GET", credentials: "include", headers: { "Content-Type": "application/json" } as HeadersInit
    });
    if (!response.ok) {
        throw new Error('Jobrole response was not ok.');
    }
    const data = await response.json();
    return data;
}


type RowData = {
    icon: JSX.Element;
    name: string;
    rate: number;
    country: string;
    currency: string;
    rateModel: string;
    startdate: string;
    enddate: string;
    hours: number;
    hoursonsite: number;
};

const BasicTable = () => {

    const txtAgreed = getText("candidatepage", "agreed")
    const txtProposal = getText("candidatepage", "candidatereceivedproposal")
    const txtCandidateAskfor = getText("candidatepage", "candidateproposed")
    const txtRate = getText("smallcomponent", "rate")
    const txtStartdate = getText("contractcomponent", "header")
    const txtEnddate = getText("smallcomponent", "enddate")
    const txtHours = getText("smallcomponent", "hours")
    const txtWeek = getText("smallcomponent", "week")
    const txtOnsite = getText("smallcomponent", "onsite")
    const txtTotal = getText("default", "total")
    const txtPer = getText("smallcomponent", "per")
    const txtLoading = getText("default", "loading")
    const txtContract = getText("default", "contract")

    const txtCommentContract = getText("candidatepage", "commentcontract")
    const rows: RowData[] = [
        { icon: <ContractIcon />, name: txtAgreed() as string, rate: 9000, country: "NO", currency: "NOK", rateModel: "day", startdate: "1/4/22", enddate: "31/1/22", hours: 43, hoursonsite: 23 },
        { icon: <ProposalIcon />, name: txtProposal() as string, rate: 9000, country: "DK", currency: "DKK", rateModel: "day", startdate: "1/4/22", enddate: "31/1/22", hours: 43, hoursonsite: 23 },
        { icon: <AskForIcon />, name: txtCandidateAskfor() as string, rate: 1350, country: "NO", currency: "NOK", rateModel: "hour", startdate: "1/6/22", enddate: "31/12/22", hours: 43, hoursonsite: 23 },
    ];

 

    // Helper function to centralize the logic for selecting the text based on the index
    const getTextByIndex = (index: number) => {
        switch (index) {
            case 0: return txtAgreed();
            case 1: return txtProposal();
            default: return txtCandidateAskfor();
        }
    };

    // Main component
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <CardWithMinimize iconName={"schedule"} header={txtStartdate() as string} type="stack" defaultOpen={true}>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <For each={rows} fallback={<div>{txtLoading()}</div>}>
                                {(row: RowData, index) => (
                                    <>
                                        <Typography variant="h5" gutterBottom>{row.name}</Typography>
                                        <Typography variant="body1" align="center">
                                            <b>{getTextByIndex(index())}</b>
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" align="center">
                                            {row.startdate} - {row.enddate}
                                        </Typography>
                                    </>
                                )}
                            </For>
                        </Grid>

                    </CardWithMinimize>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <CardWithMinimize iconName={"hours"} header={txtHours() as string} type="list" defaultOpen={true}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <For each={rows} fallback={<div>{txtLoading()}</div>}>
                                {(row: RowData, index) => (
                                    <>
                                        <Typography variant="h5" gutterBottom>{row.name}</Typography>
                                        <Typography variant="body1" align="center">
                                            <b> {getTextByIndex(index())} </b>
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" align="center">
                                            {row.hours} {txtHours()} {txtPer()}  {txtWeek()} / {row.hoursonsite} {txtOnsite()} {txtHours()} {txtPer()} {txtWeek()}
                                        </Typography>
                                    </>
                                )}
                            </For>
                        </Grid>
                    </CardWithMinimize>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <CardWithMinimize iconName={"rate"} header={txtRate() as string} type="list" defaultOpen={true}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <For each={rows} fallback={<div>{txtLoading()}</div>}>
                                {(row: RowData, index) => (
                                    <>
                                        <Typography variant="body1" align="center">
                                            <b>{getTextByIndex(index())} </b>
                                        </Typography>
                                        <ValueElement page="jobpage" label="budgetflat" value={String(row.rate)} currency={row.currency} />
                                    </>
                                )}
                            </For>
                        </Grid>
                    </CardWithMinimize>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>


                    <CardWithMinimize iconName={"comment"} header={txtCommentContract() as string} type="list" defaultOpen={true}>
                        {/* Contract Deviations */}
                        <RatingComment
                            comment="1. Notice period for customer is 30 days."
                            header={txtCommentContract()}
                            showDivider={false}
                        />
                        <RatingComment comment="2. Contract curency is NOK." showDivider={false} />

                    </CardWithMinimize>
                    <WFStatusFooter type="candidate" actionDate="18.3.22 7:48" userName="Sired Øystein" >{txtContract() as string}</WFStatusFooter>
                    <WFStatusFooter type="candidate" actionDate="1.3.22 23:24" userName="Sired Øystein" >{txtProposal() as string}</WFStatusFooter>
                </Grid>
            </Grid>
        </>
    );

}


const CandidateViewMyJobRoleComponent = () => {

    const params = useParams();
    const fetchId = params.id ? params.id : "0";

    const [job] = createResource<CandidateJobRoleFetchType>(() => fetchJob(fetchId));

    const txtJobDetails = getText("jobpage", "details")
    const txtDiscussionHistory = getText("jobpage", "discussionhistory")
    const txtCompetencies = getText("jobpage", "requestedcompetencies")

    const txtLanguages = getText("default", "languages")
    const txtMethods = getText("default", "methods")
    const txtRoles = getText("default", "roles")
    const txtTechnical = getText("default", "technical")



    const navigate = useNavigate();
    const handleNavigateRoute = (id: string) => {
        if (id)
            navigate(id);
    }

    return (
        <>
            <Card sx={{ margin: 1 }}>

                {/* First row of the job section show the local menubar, tags and other action buttons */}
                <CandidateJobMenu />

                <Suspense fallback={
                    <Alert sx={{ px: 1, py: 1 }} severity="info">
                        <GetTextUI formName={"default"} label={"loading"} />.
                    </Alert>
                }>

                    <For each={job()?.jobRole}>
                        {(item) => (
                            <>

                                <Grid container spacing={2}>

                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>


                                        <Stack spacing={2} margin={2} direction={isExtraLarge() ? "row" : "column"}>
                                            <Stack spacing={2} direction={isExtraSmall() || isSmall() ? "column" : "row"}>
                                                <Avatar sx={{ margin: 2, display: 'flex', width: 100, height: 100 }} variant="rounded" src={item.src} />

                                                <Stack margin={2} spacing={0.5}>

                                                    <Typography variant="h5">{item.companyName}</Typography>
                                                    <Typography variant="h5">{item.jobName}</Typography>

                                                    <Typography variant="h6">{item.jobRoleName}</Typography>

                                                </Stack>

                                            </Stack>

                                            <Card  >
                                                <WFStatusComponent type={"candidate"} />
                                            </Card>


                                        </Stack>

                                    </Grid>


                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <CardWithMinimize level={1} header={txtJobDetails() as string} type="stack" defaultOpen={isExtraSmall() ? false : true}>


                                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                                <Stack spacing={1} margin={2}>


                                                    <Typography variant="body1"><GetTextUI formName={"jobpage"} label={"jobdescription"} /> </Typography>
                                                    <Typography color="text.secondary">{item.jobDescription}</Typography>

                                                    <Show when={job()?.jobSOMEs}>
                                                        <br />
                                                        <Stack direction="column" alignItems="left" spacing={1}>
                                                            <Typography variant="body1"><GetTextUI formName={"jobpage"} label={"social"} /> </Typography>

                                                            <Stack direction="row" alignItems="left" spacing={1}>
                                                                <ShowSocialMedia SoMes={job()?.jobSOMEs} />
                                                            </Stack>
                                                        </Stack>
                                                    </Show>
                                                    <Typography variant="body1"><GetTextUI formName={"jobpage"} label={"contacts"} /> </Typography>
                                                    <Stack direction="column" spacing={1}  >
                                                        <For each={job()?.contacts}>

                                                            {(contact) =>
                                                                <AvatarChipWithIcon url={contact.src}>{contact.firstName} {contact.lastName}, {contact.contactRole}</AvatarChipWithIcon>
                                                            }

                                                        </For>
                                                    </Stack>

                                                    {/* Project location */}
                                                    <Show
                                                        when={item?.jobCountry || item?.jobCity}
                                                    >
                                                        <AddressElement page="contact" label="address" data={""} country={item?.jobCountry} postalCode={item?.jobPostalCode} city={item?.jobCity} street={item?.jobStreet} />

                                                    </Show>

                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>

                                                <Stack direction="column" spacing={1}>
                                                    <TextElement page="jobpage" label="jobrole" data={item.jobRoleName} />
                                                    <TextElement page="jobpage" label="jobroledescription" data={item.jobRoleDescription} />
                                                    <TextElement skip={true} page="jobpage" label="jobroleexpectation" data={item.jobRoleExpectation} />
                                                    <TextElement skip={true} page="jobpage" label="jobroleadditionalinformation" data={item.jobRoleAdditionalInformation} />

                                                    <br />
                                                    <Typography variant="body1"><GetTextUI formName={"default"} label={"duration"} /> </Typography>
                                                    <Stack direction="row" spacing={1}>
                                                        <Typography variant="body2" color="text.secondary">
                                                            {formatDateYearMonthByCountry(item.jobRoleStartDate)}<GetTextUI formName={"default"} label={"towithspaces"} />{formatDateYearMonthByCountry(item.jobRoleEndDate)}
                                                        </Typography>
                                                    </Stack>
                                                    <br />
                                                    <TextElement page="smallcomponent" label="hours" data={<GetTextUI formName={"smallcomponent"} label={"week"} /> as string} />
                                                    <Typography variant="body2" color="text.secondary"><GetTextUI formName={"smallcomponent"} label={"remote"} /> {item.workRemote} <GetTextUI formName={"smallcomponent"} label={"onsite"} /> {item.workOnsite} </Typography>

                                                    <Show when={item.maxFlatRate || item.maxOnSiteRate || item.maxRemoteRate} >
                                                        <TextElement page="default" label="budget" data={""} />
                                                        <ValueElement page="jobpage" label="budgetflat" value={item.maxFlatRate} currency={item.currency} />
                                                        <ValueElement page="jobpage" label="budgetonsite" value={item.maxOnSiteRate} currency={item.currency} />
                                                        <ValueElement page="jobpage" label="budgetremote" value={item.maxRemoteRate} currency={item.currency} />
                                                    </Show>


                                                </Stack>
                                            </Grid>

                                        </CardWithMinimize>
                                    </Grid>
                                </Grid>


                                <Show when={job()?.languages || job()?.technicals || job()?.methods || job()?.roles}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <CardWithMinimize level={1} header={txtCompetencies() as string} type="stack" defaultOpen={isExtraSmall() ? false : true}>
                                                {/* List of  different types of competencies */}

                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Stack direction="row" alignItems="center" spacing={2} margin={2}>

                                                            <Typography variant="body1" color="text.secondary" ><GetTextUI formName={"jobpage"} label={"competenciesexplained"} /> </Typography>
                                                            <Button onClick={() => navigate('/mycv')}><GetTextUI formName={"candidatepage"} label={"updatemycv"} /></Button>

                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>

                                                        <CompetenceList showAddButton={false} type="language" direction="row" header={txtLanguages() as string} options={job()?.languages} />

                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>

                                                        <CompetenceList showAddButton={false} type="method" direction="row" header={txtMethods() as string} options={job()?.methods} />

                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>

                                                        <CompetenceList showAddButton={false} type="role" direction="row" header={txtRoles() as string} options={job()?.roles} />

                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>

                                                        <CompetenceList showAddButton={false} type="technical" direction="row" header={txtTechnical() as string} options={job()?.technicals} />

                                                    </Grid>
                                                </Grid>
                                            </CardWithMinimize>

                                        </Grid>
                                    </Grid >
                                </Show>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <CardWithMinimize level={1} header={txtDiscussionHistory() as string} type="stack" defaultOpen={isExtraSmall() ? false : true}>
                                            <Grid container >
                                                <BasicTable />
                                            </Grid>

                                        </CardWithMinimize>
                                    </Grid>
                                </Grid >

                            </>
                        )}
                    </For>

                    <Show when={!job()?.jobRole || (job()?.jobRole?.length === 0)}>
                        <Alert sx={{ px: 1, py: 1 }} severity="info">
                            <GetTextUI formName={"jobpage"} label={"jobrolenotfound"} />
                        </Alert>
                    </Show>

                </Suspense>

            </Card>
            <br />
            <br />
        </>
    );
}

export { CandidateViewMyJobRoleComponent }  